<template>
  <div>
    <van-dialog
      v-model="isRate"
      show-cancel-button
      :show-cancel-button="false"
      :show-confirm-button="false "
    >
      <p style="padding:0.3rem;font-size:0.35rem;">当前系统无法获取到检测结果，请输入相似比继续，如18.60</p>
      <div style="display: flex;justify-content: center;align-items: center;margin:0.2rem 0;">
        <input type="text" v-model="inputrate" />
        <span>%</span>
      </div>
      <div style="display:flex;justify-content: space-around;">
        <p style="padding:0.3rem;" @click="isRate=false">取消</p>
        <p style="padding:0.3rem;color:#228ef7" @click="sureDialog()">确认</p>
      </div>
    </van-dialog>
    <div class="title-box">
      <van-icon name="arrow-left" class="lefticon" @click="goBack()" />
      <p>查看报告</p>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" />
    <div class="result-container" style="font-size:0.3rem;">
      <div style="border-bottom:2px solid #fff">
        <p>论文题目：{{order.title}}</p>
        <p>论文作者：{{order.writer}}</p>
        <p>检测结果：{{order.timer}}(以报告内显示为准)</p>
        <p>检测系统：{{order.bbname}}</p>
        <p>提检时间：{{order.date_pay}}</p>
      </div>
      <div class="result-btn">
        <div
          @click="toPdf(order)"
          style="background:#228ef7;width:6rem;text-align:center;color:#fff;padding:0.23rem 0;margin:0 auto; margin-bottom:0.5rem;"
          v-if="order.report_pdf_path"
        >在线预览检测报告</div>
        <div
          @click="todownload(order)"
          style="background:#76EE00;width:6rem;text-align:center;color:#fff;padding:0.23rem 0;margin:0 auto;margin-bottom:0.5rem;"
        >下载完整检测报告</div>
        <div
          @click="toQrcode(order)"
          style="background:#FF7F00;width:6rem;text-align:center;color:#fff;padding:0.23rem 0;margin:0 auto;"
        >生成检测证书，发个朋友圈</div>
      </div>
      <div style="margin-top:0.7rem;">
        <p>注：检测报告系统仅保存10天，请及时下载保存</p>
        <p>如需帮助请联系以下微信：</p>
        <p>查重问题：LwCheck 降重帮助：cx5078</p>
      </div>
    </div>
    <div class="modals modals-bottom-dialog" v-if="isSend">
      <div class="modals-cancel"></div>
      <div class="bottom-dialog-body bottom-pos">
        <div class="emailtext">
          发送报告到邮箱
          <div class="cansolex" @click="closeBtn">✘</div>
        </div>
        <input
          name="email"
          type="text"
          class="emailstyle"
          placeholder="填写有效有限地址"
          v-model="emailtext"
        />
        <div class="emailexplain">将发送查重后的报告到你的邮箱，稍后请查收，建议下载长期保存，如未收到，到邮箱垃圾箱内查看</div>
        <button class="sendbtn" @click="formSubmit(emailtext)">发送</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      order: {},
      isSend: false,
      show: false,
      ylshow: false,
      isRate: false,
      inputrate: "",
      src: "",
      actions: [
        { name: "直接下载", color: "#228ef7", className: "ftactions" },
        { name: "复制下载链接", color: "#228ef7", className: "ftactions" },
        { name: "发送到邮箱", color: "#228ef7", className: "ftactions" },
        { name: "取消", color: "#228ef7", className: "ftactions" }
      ],
      emailtext: "",
      emaimsg: ""
    };
  },
  created() {
    console.log(this.$route.query);
    this.order = this.$route.query;
    // this.src = pdf.createLoadingTask(
    //   `https://dev.lianwen.com/pdf/${this.$route.query.orderid}.pdf`
    // );
  },
  methods: {
    sureDialog(e) {
      if (this.inputrate !== "") {
        this.isRate = false;
        this.$router.push({
          path: "/qrimg",
          query: {
            title: this.order.title,
            writer: this.order.writer,
            newrate: this.inputrate,
            bbname: this.order.bbname,
            tjtime: this.order.date_pay
          }
        });
      }
    },
    toPdf(e) {
      window.location.href = `https://wanfang.lianwen.com/pdf/${e.orderid}.pdf`;
    },
    goBack() {
      this.$router.back();
    },
    formSubmit(e) {
      let reg = /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;
      if (!reg.test(e)) {
        Toast.fail("请输入正确邮箱格式");
      } else {
        let order = this.emaimsg.id;
        this.$http.getToEmail(order, { email_address: e }).then(res => {
          Toast.success("发送成功");
          this.isSend = false;
        });
      }
    },
    todownload(msg) {
      this.actions[0].id = msg.orderid;
      this.actions[1].id = msg.orderid;
      this.show = true;
      this.emaimsg = msg;
    },
    closeBtn() {
      this.isSend = false;
    },
    copyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.style.position = "fixed";
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = 0;
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        var msg = document.execCommand("copy") ? "成功" : "失败";
        Toast.success("复制成功");
      } catch (err) {}
      document.body.removeChild(textArea);
    },
    toQrcode(item) {
      console.log(item, 321312);
      if (item.timer == "" || item.timer == "0.00" || item.timer == "0.0%") {
        this.isRate = true;
      } else {
        this.$router.push({
          path: "/qrimg",
          query: {
            title: this.order.title,
            writer: this.order.writer,
            newrate: this.order.timer,
            bbname: this.order.bbname,
            tjtime: this.order.date_pay
          }
        });
      }
    },
    onSelect(item) {
      console.log(item, 321);
      if (item.name == "直接下载") {
        window.open(`https://wanfang.lianwen.com/orders/${item.id}/download`);
        this.show = false;
      } else if (item.name == "复制下载链接") {
        let url = `https://wanfang.lianwen.com/orders/${item.id}/download`;
        this.copyTextToClipboard(url);
        console.log(url, 123);
        this.show = false;
      } else if (item.name == "发送到邮箱") {
        this.show = false;
        this.isSend = true;
      } else if (item.name == "取消") {
        this.show = false;
      }
    }
  }
};
</script>

<style scoped>
.result-btn {
  margin-top: 0.7rem;
}
.result-btn div {
  margin-bottom: 1rem;
}
.title-box {
  display: flex;
  background: #228ef7;
}
.title-box p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}
.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.4rem;
}
.result-container {
  padding: 0.3rem;
}
.result-container p {
  margin-bottom: 0.3rem;
}
.title {
  text-align: center;
  font-weight: bold;
  font-size: 0.4rem;
}
.result-box {
  display: flex;
  justify-content: space-between;
  margin: 0.1rem 0;
  align-items: center;
}
.result-box-bg {
  background-color: #0498fa;
  color: #fff;
  font-size: 0.3rem;
  padding: 0 0.1rem;
  height: 0.5rem;
  line-height: 0.5rem;
}
.word >>> .mark-red {
  color: red;
}

.word >>> .section {
  text-indent: 0.7rem;
  font-size: 0.33rem;
  line-height: 0.5rem;
}

.bottom-dialog-body {
  position: absolute;
  z-index: 10001;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.modals {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modals-cancel {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.emailtext {
  font-size: 16px;
  background-color: #ccc;
  height: 0.73rem;
  line-height: 0.73rem;
  padding-left: 0.3rem;
}

.cansolex {
  float: right;
  font-size: 16px;
  padding-right: 0.3rem;
}

.sendbtn {
  display: block;
  border: none;
  height: 0.6rem;
  background-color: #228ef7;
  border-radius: 5%;
  color: #fff;
  line-height: 0.6rem;
  width: 90%;
  margin: 0.3rem auto;
}

.emailexplain {
  padding: 0 0.6rem;
  font-size: 13px;
  line-height: 0.5rem;
  color: #8b8386;
  text-indent: 0.3rem;
}

.emailstyle {
  border: 1px solid #ccc;
  display: block;
  margin: 0.16rem auto;
  width: 75%;
  padding-left: 3%;
  height: 0.65rem;
  line-height: 0.65rem;
  font-size: 16px;
  color: #8b8386;
}

.van-action-sheet__name {
  font-size: 0.32rem;
}
.noreport {
  font-size: 0.31rem;
  color: red;
  height: 12rem;
  padding: 0.3rem 0;
  margin-top: 0.7rem;
  text-align: center;
}
</style>